.hero-slider {
  overflow: hidden;
  margin: 0 0 -17px;
  $root: &;

  &__wrapper-outer {
    #{$root}--slider & {
      @include container($maxWidthOuter);
      padding: 0 40px 30px !important;
    }

    #{$root}:not(#{$root}--hero) & {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      margin: auto;
    }
  }

  &__wrapper {
    width: 100%;
    overflow: visible;
    z-index: 1;
    margin: auto;
    padding: 0;
    position: relative;

    .carousel__wrapper {
      overflow: visible;
    }
  }

  &__picture-wrap {
    position: relative;
    display: block;
    z-index: 0;
    width: 100%;
    height: 60vw;
    overflow: hidden;
    min-height: 300px;
    max-height: 440px;

    #{$root}--hero & {
      display: flex;
      justify-content: center;
      min-height: 180px;
      max-height: 390px;
      height: auto;
      text-align: center;

      @media (min-width: $screen-tablet-portrait) {
        min-height: 320px;
        max-height: none;
      }
    }

    #{$root}--slider-fullwidth & {
      height: 40vw;
      max-height: 340px;
    }
  }

  &__picture {
    margin: auto;
    margin-bottom: -3px;
    z-index: 0;
  }

  &__picture-image {
    height: 100%;
    width: auto;
    position: absolute;
    z-index: 1;
    margin: auto;
    top: -6666px;
    left: -6666px;
    right: -6666px;
    bottom: -6666px;

    #{$root}--hero & {
      align-self: center;
      position: relative;
      margin: auto;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
    }
  }

  &__box-wrap {
    @include container();
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
  }

  &__box {
    padding: 0;
    width: 90%;
    margin: auto;
    text-align: center;
    position: relative;
    min-height: 140px;
    max-width: 480px;
    z-index: 10;
    transform: translateY(-50%);
    top: 50%;

    @media (min-width: $screen-tablet-portrait) {
      width: 60%;
      margin: auto;
      min-width: 480px;
      max-width: 700px;
    }

    &-inner {
      background-color: rgb(255 255 255 / .8);
      width: 100%;
      padding: 10px;

      @media (min-width: $screen-tablet-portrait) {
        padding: 20px;
      }

      &--product {
        padding: 15px 10px;

        @media (min-width: $screen-tablet-portrait) {
          padding: 15px;
        }

        #{$root}__headline,
        #{$root}__subheadline {
          text-align: center;

          @media (min-width: $screen-tablet-landscape) {
            text-align: left;
          }
        }
      }
    }
  }

  &__text-wrap {
    @include container();
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
  }

  &__text {
    padding: 50px 0 0;
    margin: auto;
    text-align: left;
    position: relative;
    height: 100%;
    z-index: 10;

    @media (min-width: $screen-tablet-portrait) {
      position: absolute;
      width: 60%;
      height: auto;
      padding: 0;
      top: 100px;
    }

    &-inner {
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__text-headline {
    @include fontSize(40px);
    font-size: 24px;
    text-align: left;
    padding: 0;
    text-transform: none;
    line-height: 1.4;
    color: $whiteColor;
    margin: 0 0 5px;
  }

  &__text-subheadline {
    @include fontSize(16px);
    font-size: 12px;
    line-height: 1.4;
    color: $whiteColor;
    margin: 0;

    p {
      margin: 0;
      padding: 0;
    }

    a {
      color: $whiteColor;

      &:hover {
        color: $whiteColor;
        text-decoration-color: $whiteColor;
      }
    }
  }

  &__text-info {
    color: $whiteColor;

    .button {
      color: $whiteColor;

      &:hover {
        color: $whiteColor;
        text-decoration-color: $whiteColor;
      }
    }
  }

  &__container {
    #{$root}--slider & {
      transform: translate3d(0, 0, 0);
      position: relative;
      display: flex;
      transition-property: transform;
      padding: 0;
      max-width: 100%;
      height: 100%;
    }
  }

  .carousel__slide {
    position: relative;
    flex-shrink: 0;
    opacity: .7;
    width: 100%;

    &--active {
      margin: 0;
      opacity: 1;
      width: 100%;
    }
  }

  &__slide-content {
    display: block;
    width: 100%;
    height: auto;
    text-decoration: none;
  }

  & .carousel {
    &__page-info {
      display: none;
    }

    &__button {
      position: absolute;
      top: 0;
      height: 100%;
      width: 30px;

      &:visited {
        .carousel__button-icon {
          background: $heroSliderButtonTextColor;

          &::before {
            color: $heroSliderCarouselButtonColor;
          }
        }
      }

      &:hover {
        .carousel__button-icon {
          background: $heroSliderCarouselButtonHoverBackground;
          box-shadow: $heroSliderCarouselButtonBoxShadow;

          &::before {
            color: $heroSliderButtonTextColor;
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: 6666px;
        height: 100%;
        background-color: rgb(0 0 0 / .5);
      }

      &--prev {
        left: -30px;

        &::after {
          border-right: 2px solid $heroSliderButtonTextColor;
          left: -6666px + 30px;
        }

        .carousel__button-icon {
          left: -5px;

          @media (min-width: $screen-desktop-xl) {
            left: -20px;
          }
        }
      }

      &--next {
        right: -30px;

        &::after {
          left: 0;
          border-left: 2px solid $heroSliderButtonTextColor;
        }

        .carousel__button-icon {
          left: 5px;

          @media (min-width: $screen-desktop-xl) {
            left: 15px;
          }
        }
      }
    }

    &__button-icon {
      @include carouselButtonRound(30px);
      color: $heroSliderButtonTextColor;

      &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      @media (min-width: $screen-desktop-xl) {
        width: 40px;
        height: 40px;
      }
    }

    &__pagination__wrapper {
      display: block;
    }

    &__pagination {
      margin: 0;
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: -33px;
      display: flex;
      left: 0;
    }

    &__pagination-button {
      display: inline-block;
      padding: 10px 5px 20px;
      width: 50px;
      font-size: 0;
      position: relative;
      transition: color .2s;

      @media (min-width: $screen-tablet-portrait) {
        padding: 10px 10px 20px;
        width: 120px;
      }

      &:hover .carousel__pagination-progress-bar,
      &--active .carousel__pagination-progress-bar {
        background-color: $heroSliderPaginationHighlightColor !important;
      }
    }

    &__pagination-progress-bar {
      display: block;
      width: 100%;
      height: 3px;
      background-color: $heroSliderPaginationBackgroundColor;
    }
  }

  &__headline {
    @include fontSize($fontSizeXXLarge);
    position: relative;
    width: auto;
    font-weight: $heroSliderHeadlineFontWeight;
    color: $textBaseColor;
    padding: 0;
    margin: 0 0 10px;

    @media (min-width: $screen-tablet-landscape) {
      font-size: $fontSizeXLarge;
    }
  }

  &__subheadline {
    position: relative;
    width: auto;
    font-weight: $fontWeightSemiBold;
    color: $textBaseColor;
    font-size: $fontSizeMedium;
    padding: 0 0 20px;

    @media (min-width: $screen-tablet-landscape) {
      font-size: $fontSizeXXMedium;
    }

    &--product {
      padding: 0;
      font-weight: $fontWeightRegular;

      @media (min-width: $screen-tablet-landscape) {
        margin-top: 15px;
      }
    }
  }

  &__link-button {
    width: auto;
    margin: 20px auto 0;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $heroSliderLinkButtonColor;
    background: $heroSliderLinkButtonBackgroundColor;
    border-color: $heroSliderLinkButtonBorderColor;

    @media (min-width: $screen-tablet-landscape) {
      margin: 40px auto 0;
    }

    &--hover,
    &:hover {
      background: $heroSliderLinkButtonBackgroundColor;
      color: $heroSliderLinkButtonColor;
    }

    &--product {
      padding: 0 15px;
      color: $heroSliderLinkButtonProductColor;
      background: $heroSliderLinkButtonProductBackgroundColor;
      border-color: $heroSliderLinkButtonProductBorderColor;

      @media (min-width: $screen-tablet-landscape) {
        margin: 0;
      }
    }
  }

  &__info {
    margin-top: 15px;
    border-top: $borderBlackColor 1px solid;
    padding-top: 15px;

    @media (min-width: $screen-tablet-landscape) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__price {
    @include fontSize(20px);
    color: $textBaseColor;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: $screen-tablet-landscape) {
      flex-direction: row;
      align-items: center;
    }

    & > * {
      margin-right: 15px;
    }

    .price-info {
      &__old,
      &__unit {
        font-size: .65em;
      }

      &__old-text {
        @media (min-width: $screen-tablet-landscape) {
          display: block;
        }
      }

      &__unit-text {
        @media (min-width: $screen-tablet-landscape) {
          display: block;
        }
      }
    }
  }

  &__flags {
    position: absolute;
    top: -15px;
    right: -10px;

    .flag--new {
      display: $heroSliderDisplayNewFlag;
    }

    .flag--boxSetUrl {
      display: none;
    }
  }

  // PLACEHOLDER
  carousel {  // stylelint-disable-line
    display: none;
  }

  &--slider-fullwidth {
    .carousel__button {
      display: none;

      @media (min-width: $screen-tablet-portrait) {
        display: block;
        top: -185px;
      }

      @media (min-width: $screen-desktop-sm) {
        top: -195px;
      }

      &--prev {
        left: 10px;
      }

      &--next {
        right: 10px;
      }
    }

    .grid > .row:first-child > .col-xs-12 > & {
      margin: -5px 0 -24px;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0 0 -24px;
      }
    }

    .carousel {
      &__pagination-wrapper {
        @include container();
        position: relative;
        margin: 16px auto 24px;

        @media (min-width: $screen-tablet-portrait) {
          margin: 20px auto 45px;
        }

        @media (min-width: $screen-desktop) {
          margin: 24px auto 45px;
        }

        &--one-slide {
          margin: 20px auto;

          @media (min-width: $screen-tablet-portrait) {
            margin: 24px auto;
          }

          @media (min-width: $screen-desktop) {
            margin: 24px auto 0;
          }
        }
      }

      &__pagination {
        display: flex;
        justify-content: center;
        bottom: -12px;

        @media (min-width: $screen-desktop) {
          bottom: $heroSliderPaginationBottom;
        }
      }
    }
  }

  &--hero {
    .carousel {
      &__button {
        @media (min-width: $screen-tablet-portrait) {
          display: none;
        }

        @media (min-width: $screen-desktop-xl) {
          display: block;
        }

        &--prev {
          left: 10px;

          @media (min-width: $screen-desktop-xl) {
            left: 30px;
          }
        }

        &--next {
          right: 10px;

          @media (min-width: $screen-desktop-xl) {
            right: 30px;
          }
        }
      }

      &__pagination-wrapper {
        margin-top: 8px;

        @media (min-width: $screen-tablet-portrait) {
          margin: 16px auto;
        }

        @media (min-width: $screen-desktop) {
          margin: 24px auto 0;
        }
      }
    }
  }

  &__overline {
    @include fontSize(16px);
    line-height: 1.4;
    color: $textBaseColor;
    font-weight: $fontWeightBold;
    margin: 0;
  }
}
