@import '../../../bps/components/heroSlider/heroSlider.scss';

.hero-slider {
  $root: &;

  &__picture-wrap {
    position: relative;
    display: block;
    z-index: 0;
    width: 100%;
    height: 450px;
    overflow: hidden;
    min-height: 300px;
    max-height: 450px;

    #{$root}--hero & {
      display: flex;
      justify-content: center;
      min-height: 180px;
      max-height: 450px;
      height: auto;
      text-align: center;

      @media (min-width: $screen-tablet-portrait) {
        min-height: 450px;
        max-height: none;
      }
    }

    #{$root}--slider-fullwidth & {
      height: 450px;
      max-height: 450px;
    }
  }

  &__box {
    padding: 0;
    width: 90%;
    margin: auto;
    text-align: center;
    position: relative;
    min-height: 140px;
    max-width: 480px;
    z-index: 10;
    transform: translateY(-50%);
    top: 50%;

    @media (min-width: $screen-tablet-portrait) {
      width: 80%;
      margin: auto;
      min-width: 480px;
      max-width: 860px;
    }

    #{$root}--content-position-left & {
      text-align: left;
      width: 100%;
      margin: auto 0;
      padding: 0 10px;

      @media (min-width: $screen-tablet-portrait) {
        padding: 0;
      }

      @media (min-width: $screen-desktop) {
        padding: 0 20px;
      }
    }

    #{$root}--content-position-right & {
      margin: auto;
      text-align: left;

      @media (min-width: $screen-tablet-landscape) {
        width: 50%;
        margin: auto 0 auto auto;
      }
    }
  }

  &__box-inner {
    background: transparent;
    padding: 0;
  }

  &__headline {
    color: $whiteColor;
    text-align: center;
    text-transform: uppercase;
    font-size: 28px;
    line-height: 1;
    margin: 0 0 8px;
    font-weight: $fontWeightThin;

    @media (min-width: $screen-mobile) {
      font-size: 45px;
    }

    @media (min-width: $screen-tablet-portrait) {
      font-size: 50px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 55px;
    }

    #{$root}--content-position-left &,
    #{$root}--content-position-right & {
      text-align: left;
    }
  }

  &__overline,
  &__subheadline {
    color: $whiteColor;
    text-align: center;
    font-size: 24px;
    line-height: 1;
    margin: 0 0 8px;
    font-weight: $fontWeightBold;

    @media (min-width: $screen-mobile) {
      font-size: 22px;
    }

    @media (min-width: $screen-tablet-portrait) {
      font-size: 24px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 27px;
    }

    #{$root}--content-position-left &,
    #{$root}--content-position-right & {
      text-align: left;
    }
  }

  &__link-button {
    text-align: center;
    order: 3;
    color: $whiteColor;
    border: 1px solid $whiteColor;
    margin: 0 auto;
    text-transform: uppercase;
    background: transparent;
    border-radius: 0;
    width: auto;
    padding: 0 20px;
    min-width: 180px;
    font-weight: $fontWeightThin;

    &::after {
      display: none;
    }

    &:hover {
      text-decoration: none;
      border: 1px solid $buttonPrimaryBackgroundColor;
      color: $buttonPrimaryTextColor;
      background: $buttonPrimaryBackgroundColor;
    }

    #{$root}__box--product & {
      margin: 0;
      align-self: flex-start;
    }
  }

  &--slider-fullwidth {
    margin: 0 0 -40px !important;

    .carousel__pagination-wrapper {
      margin: -10px auto 10px !important;

      &--one-slide {
        margin: 0;
      }
    }

    .carousel__pagination-button {
      padding: 20px 10px;
      cursor: pointer;
    }

    .carousel__button {
      @media screen and (min-width: $screen-desktop-sm) {
        top: -280px;
      }
    }

    #{$root}__picture-wrap {
      height: 600px;
      max-height: 600px;
    }

    @media (min-width: $screen-tablet-portrait) {
      .carousel__button-icon {
        top: -25px;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
      }

      .carousel__button--prev {
        left: 0;

        .carousel__button-icon {
          left: 0;
        }
      }

      .carousel__button--next {
        right: 0;

        .carousel__button-icon {
          left: -20px;
        }
      }
    }

    @media (min-width: $screen-desktop-xl) {
      .carousel__button--prev {
        left: -50px;
      }

      .carousel__button--next {
        right: -50px;
      }
    }
  }

  &--with-content {
    #{$root}__picture {
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / .15);
        z-index: 2;
      }
    }
  }

  &__price {
    color: $whiteColor;
    margin: 0 0 20px;

    .price-info__current,
    .price-info__unit {
      color: $whiteColor;
    }
  }

  &__info {
    border-top: $whiteColor 1px solid;
  }

  &__flags {
    display: none;
  }

  .page-main--breadcrumbs-hidden .grid > .row:first-child & {
    margin-top: -24px;
  }
}
