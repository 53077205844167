/* stylelint-disable  plugin/no-unsupported-browser-features */
$fontPrimaryFamily: 'Noto Sans', sans-serif;
$fontSecondaryFamily: 'Noto Sans', sans-serif;
$fontFamily: $fontPrimaryFamily;
$fontHeadlineFamily: $fontPrimaryFamily;

$fontLineHeightRegular: 1.5;

// Font weights
$fontWeightThin: 300;
$fontWeightRegular: 320;
$fontWeightMedium: 400;
$fontWeightSemiBold: 550;
$fontWeightBold: 700;

// Font sizes
$fontSizeXXSmall: 9px;
$fontSizeXSmall: 10px;
$fontSizeSmall: 14px;
$baseFontSize: 16px;
$fontSizeFont: 16px;
$fontSizeBase: 16px;
$fontSizeMedium: 18px;
$fontSizeXMedium: 18px;
$fontSizeXXMedium: 20px;
$fontSizeXYMedium: 20px;
$fontSizeXXXMedium: 20px;
$fontSizeLarge: 23px;
$fontSizeXLarge: 25px;
$fontSizeXXLarge: 28px;
$fontSizeXXXLarge: 40px;
$fontSizeHuge: 64px;

// Colors
$blackColor: #2f2f2d;
$grayDarkColor: #3d3936;
$grayMediumColor: #505050;
$grayColor: #979797;
$grayLightColor: #d8d8d8;
$grayLighterColor: #f6f6f6;
$whiteColor: #fff;
$greenColor: #008c00;
$goldColor: #c29a6a;
$redColor: #911;
$redDarkColor: #603137;
$ctaPrimaryColor: #179287;
$ctaPrimaryHoverColor: #0f5e58;
$grayBlueColor: #3b4555; // rgb(36, 55, 71)
$blackBlueColor: #3B4555; // rgb(21, 34, 44)
$cyanColor: #179287;
$orangeColor: #ff6e00;

$brandPrimaryColor: $grayBlueColor;
$brandSecondaryColor: $blackBlueColor;
$brandTertiaryColor: $brandPrimaryColor;
$brandHighlightColor: $cyanColor;

$paypalcheckout: #fbc438;
$milesAndMoreColor: #071257;

$moodOneColor: $grayBlueColor;  // Diese Variablen bitte nicht mehr benutzen. Niemand weiß wozu diese Farben
$moodTwoColor: $blackBlueColor; // gut sind und was sich alles ändert, wenn man sie anpasst.
$moodThreeColor: $cyanColor;
$moodFourColor: $grayBlueColor;

// Basket
$basketGreenColor: #7a9e66;
$basketRedColor: #b00;
$basketPaypalColor: #eee;
$basketPaypalHoverColor: #ccc;

// Body
$bodyBackgroundColor: $grayBlueColor;

// Status
$errorColor: $redColor;
$warningColor: $orangeColor;
$waitingColor: $cyanColor;
$successColor: $greenColor;
$infoColor: $cyanColor;

$availableColor: $greenColor;
$laterAvailableColor: $orangeColor;
$soldOutColor: $redColor;
$discountColor: $redColor;
$dayOfferColor: $cyanColor;

// Text
$textLightColor: $whiteColor;
$textLightGrayColor: $grayLightColor;
$textGrayColor: $grayDarkColor;
$textMediumGrayColor: $grayMediumColor;
$textBaseColor: $blackColor;
$textDarkestColor: $blackColor;
$textWarningColor: $warningColor;
$textErrorColor: $errorColor;
$textSuccessColor: $successColor;
$textInfoColor: $infoColor;
$textHoverColor: $brandPrimaryColor;

// Borders
$borderLightColor: $grayLighterColor;
$borderWhiteColor: $whiteColor;
$borderDefaultColor: $grayLightColor;
$borderDarkColor: #aaa;
$borderBlackColor: $blackColor;
$borderPrimaryColor: $grayMediumColor;
$borderAccountAddress: $grayColor;

// Backgrounds
$backgroundWhiteColor: $whiteColor;
$backgroundGreenColor: $successColor;
$backgroundDarkColor: linear-gradient(90deg, #445160 0%, #0c161f 100%);
$backgroundTableColor: $grayLightColor;
$backgroundWarningColor: $warningColor;
$backgroundGrayColor: $grayLighterColor;
$backgroundDarkGrayColor: $grayMediumColor;
$backgroundPrimaryColor: $grayLightColor;

// Badgets
$badgetBackgroundColor: $whiteColor;
$badgetTextColor: $brandPrimaryColor;

// Remove
$removeColor: $grayBlueColor;
$removeHoverColor: $textErrorColor;

/* stylelint-enable  plugin/no-unsupported-browser-features */
