@import '../../bps/variables/componentConfig.scss';

// Button
$buttonBorderWidth: 1px;
$buttonBorderRadius: 0;
$buttonPrimaryHeightMobile: 40px;
$buttonPrimaryHeightDesktop: 43px;
$buttonShadow: none;
$buttonShadowActive: none;
$buttonCounterWidth: 42px;
$buttonCounterHeight: 42px;
$buttonCounterWidthMobile: 42px;
$buttonCounterHeightMobile: 42px;
$buttonAddToBasketWidth: 42px;
$buttonAddToBasketHeight: 42px;
$buttonDarkColor: $brandPrimaryColor;
$buttonBackgroundColor: $brandPrimaryColor;
$buttonBorderColor: $brandPrimaryColor;
$buttonPrimaryBorderColor: $ctaPrimaryColor;
$buttonPrimaryBackgroundColor: $ctaPrimaryColor;
$buttonPrimaryHoverBackgroundColor: $ctaPrimaryHoverColor;
$buttonSecondaryBorderColor: $brandPrimaryColor;
$buttonSecondaryBackgroundColor: $brandPrimaryColor;
$buttonSecondaryTextColor: $whiteColor;
$buttonSecondaryHoverBackgroundColor: $brandSecondaryColor;
$buttonSecondaryBorderColor: $brandSecondaryColor;
$buttonSecondaryHoverTextColor: $whiteColor;

// AddToBasket Button
$buttonAddToBasketBackgroundColor: $ctaPrimaryColor;
$buttonAddToBasketBorderColor: $ctaPrimaryColor;
$buttonAddToBasketHoverBackgroundColor: $ctaPrimaryHoverColor;

// Counter Button
$buttonCounterBorderColor: $brandPrimaryColor;
$buttonCounterHoverBorderColor: darken($brandPrimaryColor, 20%);

// Input
$inputFieldFontSizeMobile: 14px;
$inputFieldFontSizeDesktop: 15px;
$inputLabelFontSize: 11px;
$inputShadow: 0 0 10px 0 rgb(0 0 0 / .2);
$fontSizeInputField: 14px;
$inputIconColor: $brandPrimaryColor;
$inputBorderColor: $brandPrimaryColor;
$inputFocusBorderColor: $brandHighlightColor;
$inputSuccessBorderColor: $brandPrimaryColor;
$inputRadioCheckedColor: $brandPrimaryColor;
$inputIconValidColor: $brandHighlightColor;
$inputIconValidColor: $textBaseColor;
$inputIconSpriteMinWidth: 52px;

// Select
$selectTextColor: $textBaseColor;
$selectInactiveColor: $brandPrimaryColor;
$selectActiveColor: $textBaseColor;
$selectFontWeightBold: $fontWeightSemiBold;
$selectCustomOptionSelectedColor: $textBaseColor;
$selectCustomOptionSelectedFontWeight: $fontWeightSemiBold;
$selectArrowFontWeight: $fontWeightRegular;

// checkbox
$checkboxLabelPaddingLeftMobile: 32px;
$checkboxLabelPaddingLeftDesktop: 32px;
$checkboxSwitchWrapperMargin: -3px 0 -7px;
$checkboxSwitchMargin: 2px 0 6px 1px;
$checkboxSwitchLabelMargin: 0 12px 3px 12px;

// Address Suggestion
$addressSuggestionSelectedFontWeight: $fontWeightSemiBold;

// addressForm
$addressFormRadiosBorderRadius: 0;
$addressFormRadiosBorderChecked: 2px solid $inputRadioCheckedColor;

// loader
$loaderBackgroundColor: rgb(222 222 222 / .2);

// rangeSlider
$rangeSliderFontSize: 15px;
$rangeSliderHighlightColor: $brandHighlightColor;
$rangeSliderLineColor: $grayLightColor;
$rangeSliderOuterLineColor: $grayLightColor;
$rangeSliderBorder: 2px solid $whiteColor;
$rangeSliderInputBorder: 1px solid $inputBorderColor;
$rangeSliderInputBorderActive: 1px solid $inputFocusBorderColor;
$rangeSliderInputColor: $inputFieldColor;
$rangeSliderInputBackground: $whiteColor;
$rangeSliderHandleBackground: $rangeSliderHighlightColor;
$rangeSliderHandleBorder: 2px solid $whiteColor;

// Header
$headerIconColor: $whiteColor;
$headerSearchIconColor: $whiteColor;
$loggedInIconColor: $whiteColor;

// BurgerNavTree
$burgerNavTreeLinkFontWeight: $fontWeightRegular;
$burgerNavTreeLinkFontSize: 20px;

// Carousel
$carouselButtonBackground: transparent;
$carouselPaginationHoverBackground: transparent;
$carouselButtonTextColor: $whiteColor;
$carouselButtonHoverTextColor: $whiteColor;
$carouselButtonBoxShadow: none;
$carouselButtonPrimaryColor: $brandPrimaryColor;
$paginationLabelColor: $whiteColor;
$carouselButtonFontSizeMobile: 11px;
$carouselButtonFontSizeTablet: 28px;
$carouselButtonFontSizeDesktop: 38px;
$carouselPaginationHighlightColor: $brandHighlightColor;
$carouselButtonTextShadow: 0 0 1px $brandPrimaryColor, 0 0 1px $brandPrimaryColor, 0 0 1px $brandPrimaryColor, 0 0 1px $brandPrimaryColor;
$carouselPaginationBackgroundColor: $whiteColor;
$carouselButtonHoverBackgroundColor: transparent;
$carouselPaginationFontSize: 18px;

// creditcard
$creditcardInfotextDisplay: none;

// ImageTextAction Teaser
$imageTextTeaserBoxBackground: $whiteColor;
$imageTextTeaserBoxPosition: static;
$imageTextTeaserHeadlineColor: $whiteColor;
$imageTextTeaserHeadlineSize: $fontSizeXLarge;
$imageTextTeaserWideSublineFontSize: 16px;

// ProductTeaser
$productTeaserPriceUnitPadding: 0;

// ProductBox
$productBoxListBorder: 0;
$productBoxWhishListBorder: 1px solid $grayColor;
$productBoxNameColor: $blackColor;
$productBoxShortDescriptionColor: $grayDarkColor;
$productBoxButtonWishlistColor: $moodThreeColor;
$productBoxWishlistColor: $moodThreeColor;
$productBoxHeaderColor: $whiteColor;
$productBoxLoadingColor: $moodOneColor;
$productBoxStatusPadding: 14px 12px;
$productBoxLinkColor: $grayDarkColor;
$productBoxLinkFontSize: $fontSizeXSmall;
$productBoxWideBottleBackground: linear-gradient(to bottom, #fff 50%, #c4c3c2 100%);
$productBoxStatusBorderRadius: 6px;
$productBoxUnavailableTextTransform: uppercase;

// ProductBoxBasket
$productBoxBasketHeaderBackground: $grayBlueColor;

// MoodTeaser
$moodTeaserHeadlineColor: $textLightColor;
$moodTeaserNoImgTextColor: $textLightColor;
$moodTeaserTextColor: $textLightColor;
$moodTeaserTextShadow: none;

// ServiceTeaser
$serviceIconColor: $brandPrimaryColor;
$serviceTeaserBorder: 0 none;

// Tags
$tagBorderRadius: 5px;
$tagFontSize: 16px;
$tagPadding: 5px 10px;
$tagPaddingRight: 35px;
$tagColor: $whiteColor;
$tagBackgroundColor: $brandPrimaryColor;
$tagBorderColor: $whiteColor;
$tagHoverColor: $whiteColor;
$tagBorderHoverColor: $whiteColor;
$tagBackgroundHoverColor: $brandSecondaryColor;
$tagCloseColor: $whiteColor;

// origin-highlights
$originHighlightsBackground: transparent;
$originHighlightsBorderRadius: $tagBorderRadius;
$originHighlightsBorder: 1px solid $whiteColor;

// giftGreetingText
$giftGreetingTextCheckboxColor: $grayBlueColor;

// productOrderPosition
$productOrderPositionRemoveIcon: 'close';
$productOrderPositionRemovePadding: 8px 20px 10px;
$productOrderPositionPriceFontSize: 12px;
$productOrderPositionPriceInfoCurrentFontSize: 18px;
$productOrderPositionPriceInfoCurrentMobileFontSize: 14px;
$productOrderPositionPriceInfoOldValueFontSize: 18px;
$productOrderPositionPriceInfoOldValueMobileFontSize: 14px;
$productOrderPositionPromotionBg: $redColor;
$orderPositionNameFontWeight: $fontWeightSemiBold;

// availabilityInfo
$availabilityInfoFontDesktop: 14px;

// Counter
$buttonCounterBorderRadius: 5px;
$buttonCounterHideButtonPosition: 5px;
$buttonCounterShowButtonPosition: -17px;
$buttonCounterLineHeightIncreaseButton: 25px;

// Flags
$flagUndefinedColor: $brandPrimaryColor;
$flagNewColor: $brandPrimaryColor;
$flagStoererColor: #179287;
$flagBoxSetUrlColor: $whiteColor;

// Pagination
$paginationButtonTextColor: $brandPrimaryColor;
$paginationSelectBorderColor: $brandPrimaryColor;
$paginationSelectColor: $whiteColor;
$paginationSelectArrowColor: $whiteColor;
$paginationButtonTextColor: $whiteColor;
$paginationPagesCountColor: $whiteColor;
$paginationSelectArrowFontSize: 12px;

// siblingNavigation
$siblingNavigationBackground: linear-gradient(90deg, transparentize(#0c161f, 1), transparentize(#0c161f, .1) 100%);
$siblingNavigationColor: $whiteColor;
$siblingNavigationActiveColor: $whiteColor;
$siblingNavigationActiveBorderBottom: 2px $whiteColor solid;
$siblingNavigationCountColor: $whiteColor;

// Datepicker
$datePickerHeaderBackgroundColor: $brandPrimaryColor;
$datePickerHoverColor: $brandPrimaryColor;
$datePickerCheckedColor: $brandPrimaryColor;

// goTop
$goTopColor: $grayBlueColor;
$goTopBackgroundColor: $whiteColor;
$goTopBoxShadow: 0 0 0 1px $grayBlueColor;
$goTopHoverColor: $grayBlueColor;
$goTopHoverBackgroundColor: $whiteColor;
$goTopFontSizeMobile: 17px;
$goTopFontSizeDesktop: 25px;
$goTopIcon: arrowUp;
$goTopIconTextShadow: 0 0 1px $whiteColor, 0 0 1px $whiteColor, 0 0 1px $whiteColor, 0 0 1px $whiteColor;
$goTopIconRightMobile: 14px;
$goTopWidthMobile: 50px;

// Loader
$loaderColor: $brandPrimaryColor;

// ScrollToContentButton
$scrollToContentButtonColor: $whiteColor;

// searchAutosuggest
$searchAutosuggestHoverBorderColor: $brandPrimaryColor;

// Filters
/* stylelint-disable plugin/no-unsupported-browser-features */
$filtersColor: $whiteColor;
$filtersIconColor: $whiteColor;
$filtersBorderColor: $whiteColor;
$filtersTextTransform: inherit;
$filtersLetterSpacing: 0;
$filtersProductsSortBorder: 1px solid $whiteColor;
$filtersInputFocusBorderColor: $inputBorderColor;
$filtersLoaderBackground: rgb(0 0 0 / .1);
$filtersLoaderColor: $whiteColor;
$filtersLoaderOpacity: .3;
$filtersWrapperBg: $backgroundDarkColor;
/* stylelint-enable */

// Filter
/* stylelint-disable plugin/no-unsupported-browser-features */
$filterColor: $whiteColor;
$filterCountColor: $whiteColor;
$filterCheckboxColor: $brandPrimaryColor;
$filterCheckboxActiveColor: $brandPrimaryColor;
$filterLabelFontSize: 16px;
/* stylelint-enable */

// FilterPrice
/* stylelint-disable plugin/no-unsupported-browser-features */
$filterPriceUnitColor: $whiteColor;
/* stylelint-enable */

// sidebarMenu
$sidebarMenuSelectedFontWeight: $fontWeightSemiBold;

// Grid Backgrounds
$gridBackgroundColor3: $brandPrimaryColor;
$gridBackgroundColor4: $brandSecondaryColor;
$gridBackgroundColor5: $backgroundDarkColor;
$gridElementTeaserBackground: $whiteColor;
$gridElementTeaserTextColor: #2f2f2d;

// product-order-free-item
$productOrderFreeItemHeadlineColor: $grayBlueColor;
$productOrderFreeItemNameColor: $grayBlueColor;
$productOrderFreeItemBackgroundColor: $grayBlueColor;

// ProductAwards
$productAwardItemBackground: $whiteColor;
$productAwardItemBoxShadow: none;
$dividerColor: $grayLightColor;

// OrderSummary
$orderSummaryFontWeightBold: $fontWeightSemiBold;

// VoucherForm
$voucherFormFontWeightBold: $fontWeightSemiBold;

// CartPage
$cartPageHeaderMarginMobile: 15px 0;
$cartPageHeaderMarginTablet: 20px auto 0;
$cartPageHeaderMarginDesktop: 30px auto 0;

// form Headline
$formHeadlineColor: $brandPrimaryColor;
$formHeadlineFontWheight: $fontWeightBold;

// ProductsOrderTable
$productsOrderTableHeaderPadding: 30px 0 0;
$productsOrderTableSplitMessageBackground: $grayLighterColor;

// Sort
$productsSortLabelColor: $whiteColor;
$productsSortSelectColor: $whiteColor;
$productsSortSelectFontWeight: $fontWeightSemiBold;
$productsSortSelectArrowColor: $whiteColor;
$productsSortSelectArrowFontSize: 12px;

// addToCartLayer
$addToCartLayerTopTablet: -5px;
$addToCartLayerTopDesktop: -150px;
$addToCartLayerRightTablet: 31px;
$addToCartLayerRightDesktop: 46px;

// treeNavigation
$treeNavigationBorderLeft: $whiteColor 2px solid;
$treeNavigationTextColor: $whiteColor;
$treeNavigationHighlightColor: $whiteColor;
$treeNavigationFontWeight: $fontWeightRegular;
$treeNavigationFontWeightActive: $fontWeightSemiBold;

// DropdownNavigation
$dropdownNavigationBorder: 0;
$dropdownNavigationBorderBottom: 0;
$dropdownNavigationItemSize: $fontSizeFont;

// productsListPage
$productsListPageNavigationPadding: 0 0 20px;
$productsListPageFiltersPadding: 0;

// productContentBlock
$productContentBlockColor: $whiteColor;

// accountWelcomeView
$accountWelcomeViewBoxBorder: #aaa 1px solid;

// userArea
$userAreaBoxContainerBorderMobile: none;
$userAreaBoxContainerBorderDesktop: 1px solid $borderDarkColor;
$userAreaBoxHeadlineColor: $brandPrimaryColor;
$userAreaBoxHeadlineFontSize: $fontSizeXXXMedium;
$userAreaBoxHeadlineFontWeight: $fontWeightSemiBold;
$accountNewsletterViewSubheadlineFontWeight: $fontWeightSemiBold;
$accountNewsletterViewSubheadlineEMailAddressFontWeight: $fontWeightSemiBold;
$accountNewsletterViewInfoTextPaddingBottom: 12px;

// headerCheckout
$headerCheckoutBoxShadow: 0 0 5px 1px rgb(0 0 0 / .5);
$headerCheckoutBackground: $grayBlueColor;
$headerCheckoutMobileMinHeight: 65px;
$headerCheckoutDesktopMinHeight: 109px;
$headerCheckoutMobileWidth: 90px;
$headerCheckoutDesktopWidth: 188px;
$headerCheckoutPadding: 0;

// checkoutSteps
$checkoutStepsLabelFontWeightLegacy: $fontWeightSemiBold;

// paymentMethodForm
$paymentMethodFormLabelFontWeight: $fontWeightSemiBold;
$paymentMethodFormLabelCheckedColor: $brandHighlightColor;
$paymentMethodFormEditTextTransform: none;
$paymentMethodFormEditFontSize: $fontSizeSmall;
$paymentMethodCCOptionFontSize: $fontSizeSmall;

// deliveryOptionForm
$deliveryOptionFormPriceDisplay: inline;
$deliveryOptionFormLabelFontWeight: $fontWeightSemiBold;
$deliveryOptionFormLabelCheckedColor: $brandHighlightColor;
$deliveryOptionFormForecastTextFontSizeMobile: 12px;
$deliveryOptionFormForecastTextFontSizeDesktop: 16px;

// checkout
$checkoutFontWeight: $fontWeightSemiBold;
$checkoutBoxContentFontSize: 16px;
$checkoutSummarySmallFontSize: 12px;

// orderSummarySmall
$orderSummarySmallFontSize: 16px;
$orderSummarySmallFontWeight: $fontWeightSemiBold;
$orderSummarySmallSmallTextFontWeight: $fontWeightRegular;
$orderSummarySmallSmallTextFontSize: 12px;

// deliveryForecastMessage
$deliveryForecastMessageFontSizeMobile: 12px;
$deliveryForecastMessageFontSizeDesktop: 16px;

// checkoutStepSummary
$checkoutStepSummaryButtonFontWeight: $fontWeightSemiBold;
$checkoutStepSummaryTextLinkColor: $brandPrimaryColor;
$checkoutStepSummaryBoxTextFontSizeMobile: 14px;
$checkoutStepSummaryBoxTextFontSizeDesktop: 16px;
$checkoutStepSummaryBoxTextFontWeight: $fontWeightSemiBold;
$checkoutStepSummaryCheckboxLabelColor: $grayDarkColor;
$checkoutStepSummaryCheckboxMaxWidth: 680px;
$checkoutStepSummaryCheckboxPadding: 5px 0 0 2px;
$checkoutStepSummaryCheckboxLabelLineHeight: 1.2;
$checkoutStepSummaryBoxHeaderFontSizeMobile: 16px;
$checkoutStepSummaryBoxHeaderFontSizeDesktop: 18px;
$checkoutStepSummaryBoxContentFontSizeMobile: 14px;
$checkoutStepSummaryBoxContentFontSizeDesktop: 16px;
$checkoutStepSummaryTermsFontSize: 12px;

// productDetailedPage
$productDetailedPageBackgroundS: linear-gradient(
  $backgroundWhiteColor 2%,
  #e8e8e8 340px,
  $backgroundWhiteColor 390px
);
$productDetailedPageBackgroundM: linear-gradient(
  $backgroundWhiteColor 2%,
  #e8e8e8 340px,
  $backgroundWhiteColor 370px
);
$productDetailedPageBackgroundL: linear-gradient(
  $backgroundWhiteColor 2%,
  #e8e8e8 450px,
  $backgroundWhiteColor 515px
);
$productDetailedPageZoomIconSize: 25px;
$productDetailedPageBottomBackground1: $backgroundDarkColor;
$productDetailedPageBottomBackground2: $brandPrimaryColor;
$productDetailedPageBottomBackgroundFirst: $blackBlueColor;
$productDetailedPageBottomColor: $whiteColor;
$productDetailedPageBottomHeadlineFontSize: 28px;
$productDetailedPageBottomHeadlineFontWeight: $fontWeightSemiBold;
$productDetailedPageHeadlineFontWeight: $fontWeightMedium;
$productDetailedPageInfoLinkFontSize: 12px;

// productVariationInput
$productVariationInputOptionsBoxShadow: none;
$productVariationInputSelectBackground: $grayLighterColor;
$productVariationInputValueFontWeight: $fontWeightRegular;

// SaleboxBar
$saleBoxBarNameColor: $textBaseColor;

// productNavigation
$productNavigationTop: 75px;
$productNavigationLinkColor: $whiteColor;
$productNavigationLinkActiveColor: $brandHighlightColor;
$productNavigationRowBackground: $grayBlueColor;
$productNavigationSaleRowBackground: $whiteColor;

// productsListPage
$productsListInsertionTextColor: $whiteColor;
$productsListInsertionHeadlineFontWeight: $fontWeightSemiBold;

// headline
$headlineH1FontSize: 32px;
$headlineH1FontWeight: $fontWeightRegular;
$headlineH2FontSize: 28px;
$headlineH2FontWeight: $fontWeightBold;
$headlineH3FontSize: 20px;
$headlineH3FontWeight: $fontWeightSemiBold;
$headlineH4FontSize: 16px;
$headlineH4FontWeight: $fontWeightSemiBold;

// htmlBlock
$htmlBlockTextFontSize: 16px;
$htmlBlockTextFontSizeMedium: 18px;
$htmlBlockTextFontWeight: $fontWeightSemiBold;
$htmlBlockTextLineHeight: 1.4;
$htmlBlockTextColor: $textBaseColor;
$htmlBlockTableColor: $grayColor;

// teaserHeadline
$teaserHeadlineFontSize: 28px;
$teaserHeadlineFontWeight: $fontWeightSemiBold;
$teaserHeadlineColor: $whiteColor;

// consentCookieBox
$consentCookieBoxLogoWidth: 115px;

// ContentStarter Teaser
$contentStarterHeaderColor: $brandPrimaryColor;
$contentStarterHeaderFontSizeDesktop: 32px;
$contentStarterHeaderFontWeight: $fontWeightSemiBold;
$contentStarterSeparatorDisplay: none;
$contentStarterTextFontSize: 16px;

// directOrderTeaser
$directOrderTeaserHeadlineFontSizeMobile: 25px;
$directOrderTeaserHeadlineFontSizeDesktop: 28px;
$directOrderTeaserHeadlineFontWeight: $fontWeightSemiBold;
$directOrderTeaserHeadlineDisclaimerFontSize: 14px;
$directOrderTeaserHeadlineNewsletterCheckFontSize: 16px;
$directOrderTeaserHeadlineNewsletterCheckSmallFontSize: 12px;
$directOrderTeaserHeadlineTextFontSize: 16px;
$directOrderTeaserHeadlineCheckboxLabelFontSize: 16px;
$directOrderTeaserHeadlineErrorFontSize: 16px;

// productFeatures
$productFeaturesListItemMargin: 0 10px 20px 0;
$productFeaturesListLabelTextTransform: uppercase;
$productFeaturesListLabelColor: $grayDarkColor;
$productFeaturesListLabelFontWeight: $fontWeightSemiBold;
$productFeaturesListBorderBottom: none;
$productFeaturesListValueColor: $grayDarkColor;
$productFeaturesListBorderTop: solid 1px $grayDarkColor;

// NewsletterPage
$newsletterPageSubHeadlineColor: $grayBlueColor;
$newsletterPageEmailValueColor: $grayBlueColor;
$newsletterPageTextSucessColor: $grayBlueColor;
$newsletterPageCheckboxTop: 0;
$newsletterPageCheckboxTransform: none;

// navigation
$navigationElementColor: $whiteColor;

// infoPopup
$infoPopupFontSize: 14px;
$infoPopupLineHeight: 1.4;
$infoPopupPadding: 16px 26px 16px 16px;

// HeroSlider
$heroSliderPaginationHighlightColor: $cyanColor;
$heroSliderCarouselButtonHoverBackground: none;
$heroSliderCarouselButtonBoxShadow: none;
$heroSliderPaginationBottom: -8px;
$heroSliderDisplayNewFlag: none;

// imageSliderTeaser
$imageSliderTeaserTextColor: $grayColor;
$imageSliderTeaserTextFontSize: 12px;

// table
$tableFontWeightBold: $fontWeightSemiBold;
$tableBorderColor: $textDarkestColor;
$heroSliderCarouselButtonColor: $whiteColor;

// teaserGroupSlider
$teaserGroupSliderItemsContainerMarginTop: 30px;
$teaserGroupSliderItemsContainerMarginBottom: 30px;

// processTeaser
$processTeaserFontSize: 14px;

// categoryPage
$categoryPageHeadlineColor: $textLightColor;

// contentIndex
$contentIndexHeadColor: $whiteColor;
$contentIndexTextColor: $whiteColor;
$contentIndexCountryColor: $whiteColor;
$contentIndexArrowColor: $whiteColor;
$contentIndexHeadTopM: 45px;
$contentIndexHeadTopD: 75px;

// contentIndexHeader
$contentIndexHeaderBackgroundColor: transparentize($blackBlueColor, .1);
$contentIndexHeaderTextColor: $whiteColor;
$contentIndexHeaderDisabledColor: $grayColor;
$contentIndexHeaderPadding: 8px;

// readMore
$readMoreBtnFontWeight: $fontWeightSemiBold;

// Footer
$footerServiceBackgroundColor: $brandPrimaryColor;
$footerServicePadding: 24px 16px;
$footerServiceTextColor: $whiteColor;
$footerServiceBlankColor: $whiteColor;
$footerServiceLinkColor: $whiteColor;
$footerServiceHeadlineFirstTop: 8px;
$footerServiceLineHeight: 155%;
$footerServiceIconFontSize: 35px;
$footerServiceDarkColor: $grayBlueColor;

// productDescription
$productDescriptionImageDistance: 35px;

// footerSeo
$footerSeoColor: $whiteColor;
$footerSeoFontSize: $baseFontSize;
$footerSeoBorderTop: 1px solid $whiteColor;
$footerSeoImgContainerPaddingTop: 43px;
$footerSeoImgContainerPaddingTopChild: 20px;

// accountAddresses
$accountAddressesBoxPrimaryBorderColor: $brandPrimaryColor;
$accountAddressesListMinHeight: 40vh;

// wishlistEdit
$wishlistEditListNameArrowColor: $brandPrimaryColor;

// productBoxSet
$productBoxSetBorderBottom: $grayLightColor 1px solid;

// Breadcrumbs
$breadcrumbsTextColor: $grayMediumColor;
$breadcrumbsSeparatorColor: $grayMediumColor;
$breadcrumbsLinkAfterMarginTop: -2px;

// inspirationList
$inspirationListMarginTop: -24px;
$inspirationListHeadlineTextColor: $whiteColor;

// accountOrderListView
$accountOrdersListViewPadding: 20px;
$accountOrdersListViewContentColor: $whiteColor;
$accountOrdersListViewContentBorder: 2px solid $grayLightColor;
$accountOrdersListViewOrderBorderBottom: 1px solid $grayLightColor;

// singleOrderDetails
$singleOrderDetailsOrderHeaderBorderTop: 1px solid $grayLightColor;

// similarWinesSuggestTeaser
$similarWinesSuggestTeaserHeadlineFamily: $fontHeadlineFamily;
$similarWinesSuggestTeaserHeadlineSmallFamily: $fontHeadlineFamily;
$similarWinesSuggestTeaserDescriptionFamily: $fontHeadlineFamily;

// referAFriendReward
$referAFriendRewardServiceIconSize: 70px;
$referAFriendRewardServiceIconColor: $whiteColor;
$referAFriendRewardServiceColor: $whiteColor;
$referAFriendRewardServiceColorFontSize: 16px;
$referAFriendRewardTextHeaderSize: 18px;
$referAFriendRewardTextHeaderBackground: $brandSecondaryColor;
$referAFriendRewardTextHeaderColor: $whiteColor;
$referAFriendRewardTextContentBackground: $whiteColor;
$referAFriendRewardTextContentColor: $textDarkestColor;
$referAFriendRewardDisclaimerColor: $whiteColor;
$referAFriendRewardDisclaimerMargin: 40px auto;
$referAFriendRewardAddressBoxSuccessPadding: 20px;
$referAFriendRewardAddressBoxButtonMargin: 10px 10px 0;
$referAFriendRewardProductsMarginTop: 0;
$referAFriendRewardProductBackgroundColor: #f6f6f6;
$referAFriendRewardProductBackground: linear-gradient(180deg, #fff 50%, #c4c3c2);

// accountReferAFriendReward
$accountReferAFriendRewardListBackground: $grayLighterColor;
$accountReferAFriendRewardBorderBottom: 1px solid $textDarkestColor;
$accountReferAFriendRewardBarBackground: $grayLightColor;
$accountReferAFriendRewardBarSuccessBackground: $brandPrimaryColor;

// accordionList
$accordionListIconColor: $brandPrimaryColor;
$accordionListBorderColor: $whiteColor;
$accordionListBorderHoverColor: $brandHighlightColor;
$accordionListHeadlineBackgroundColor: $whiteColor;
$accordionListHeadlineColor: $brandPrimaryColor;
$accordionListContentBackgroundColor: transparent;
$accordionListHeadlineHoverColor: $whiteColor;
$accordionListHeadlineHoverBackgroundColor: $brandHighlightColor;

// paging
$paginationButtonMarginTop: 0;

// $registerForm
$registerFormAdditionToggleMargin: 10px 0;

//sidePanel
$sidePanelBackground: #f1ede7;
$sidePanelCloseIconFontSize: 15px;

//slotMachine
$slotMachineTeaserBackgroundLeft: #ddd;
$slotMachineTeaserBackgroundRight: #ffe9e6;
$slotMachineBtnBackgroundColor: $whiteColor;
$slotMachineVoucherTextColor: #fb5b4f;

//SlotMachineColumn
$slotMachineColumnBorderColor: #ecb748;
$slotMachineColumnItemBorderColor: #c98b25;

// registerForm
$registerFormSubHeadlineFontSize: 18px;

// registerAddressForm
$registerAddressFormOptionAreaBackground: $grayLighterColor;

// sidePanel
$sidePanelCheckoutFormBackground: $whiteColor;

// FavoriteWineTeaser
$favoriteWineBackground: $grayLighterColor;
