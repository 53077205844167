// Fonts
@mixin fontSize($fontSize: $baseFontSize) {
  $mSize: ceil($fontSize * .87);
  $tSize: ceil($fontSize * .93);
  //
  font-size: $mSize;

  @if $tSize != $mSize {
    @media (min-width: $screen-tablet-portrait) {
      font-size: $tSize;
    }
  }

  @if $fontSize != $tSize {
    @media (min-width: $screen-laptop) {
      font-size: $fontSize;
    }
  }
}
